import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// components
import Fragranze from "../../components/fragranze";
import Personalizza from "../../components/personalizza";
import SalvaFreschezza from "../../components/salvafreschezza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

const WhiteButton = styled(Button)(({ theme }) => ({
  border: "none",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
  "&:hover": {
    border: "none",
    backgroundColor: theme.palette.grey[200],
  },
}));

const HomePage = () => {
  return (
    <Page
      lang="en"
      title="Disposable scented wet wipes"
      description="Producing single-use scented wet wipes for Ho.Re.Ca. since 1998. Our single-use wet wipes are made from a special fabric making them durable yet gentle on the skin."
      type="WebPage"
      readMore={["/wipes"]}
      image="/home.jpg"
    >
      <Hero
        title="Producing single-use scented wet wipes for Ho.Re.Ca. since 1998"
        description={
          <>
            Our <strong>single-use wet wipes</strong> are made from a special
            fabric making them <strong>durable</strong> yet{" "}
            <strong>gentle on the skin</strong>. They are ideal for cleaning
            hands at the end of a meal.
            <Box pt={4}>
              <Link
                type="internal"
                href="/en/wipes"
                title="Learn more about the wipes"
              >
                <WhiteButton size="large" variant="contained" color="primary">
                  Our Wipes
                </WhiteButton>
              </Link>
            </Box>
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../../images/products/salviette-monouso-per-la-ristorazione-mettifogo.png"
            alt="Disposable wet wipes for bars, restaurants, and hotels"
            layout="fullWidth"
          />
        }
      />

      <Fragranze language="en" />
      <SalvaFreschezza language="en" />

      <Section
        maxWidth="md"
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      >
        <Stack spacing={2} textAlign="center">
          <Typography variant="h3" component="h2">
            Disposable scented wet wipes,
            <br /> perfect for bars, restaurants, and hotels
          </Typography>
          <Typography>
            Discover the various scents and find the one that best suits your
            establishment, bar, or restaurant.
          </Typography>

          <Box>
            <Link
              type="internal"
              href="/en/wipes"
              title="Learn more about the wipes"
            >
              <Button size="large" variant="contained">
                Learn more
              </Button>
            </Link>
          </Box>
        </Stack>
      </Section>

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Freshness-saving packs"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                About Us
              </Typography>
              <Typography>
                In 1989 we began producing{" "}
                <strong>customized products for the restaurant industry</strong>
                , adding in the year 1998 the production line for disposable wet
                wipes.
              </Typography>
              <Typography>
                Starting with classic disposable lemon wipes, we then created a
                wide range of scents.
              </Typography>
              <Typography>
                Our <strong>single-use wet wipes</strong> are designed to last,
                thanks to the quality of the outer wrapper, designed to retain
                the moisture and fragrance of the fragrance.
              </Typography>
              <Box>
                <Link
                  type="internal"
                  href="/en/about-us"
                  title="Learn more about us"
                >
                  <Button
                    style={{ padding: 8, marginLeft: -8 }}
                    size="large"
                    variant="text"
                    color="info"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Learn more
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Personalizza language="en" />
    </Page>
  );
};

export default HomePage;
